<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                    :headers="headers"
                    :items="extraservices"
                    :items-per-page="-1"
                    :search="searchExtraService"
                    :loading="loadingData"
                    item-key="id"
                    sort-by="id"
                    disable-sort
                    no-data-text="No hay datos para mostrar..."
                    loading-text="Cargando los datos, por favor espere!"
                    class="elevation-1"
                    :footer-props="{
                      showFirstLastPage: false,
                      disableItemsPerPage: false,
                      itemsPerPageAllText: 'Todas',
                      itemsPerPageText: 'Filas por página:'
                    }"
            >
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title class="text-subtitle-1 text-md-h5">Nomenclador de Servicios Extras</v-toolbar-title>
                        <v-divider
                                class="mx-4"
                                inset
                                vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                    id="findtext-extraservice"
                                    label="Buscar.."
                                    name="findextraservice"
                                    outlined
                                    dense
                                    single-line
                                    clearable
                                    background-color="white"
                                    hide-details
                                    v-model="searchExtraService"
                                    class="white--text mt-0 pt-0 mr-2"
                                    prepend-inner-icon="mdi-magnify"></v-text-field>
                        </v-responsive>
                        <v-dialog
                                :fullscreen="$vuetify.breakpoint.smAndDown"
                                v-model="dialog"
                                max-width="600px"
                                persistent
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn  :small="$vuetify.breakpoint.smAndDown"
                                        color="blue darken-1"
                                        dark
                                        depressed
                                        elevation="0"
                                        class="mb-2"
                                        v-bind="attrs"
                                        v-on="on"
                                        @click="newItem">
                                    Adicionar
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form
                                        ref="form"
                                        v-model="valid">
                                    <v-card-title class="text-h6 grey lighten-2">
                                        <span class="headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-text-field
                                                            v-model="editedItem.name"
                                                            label="Nombre"
                                                            :rules="nameRules"
                                                            required>
                                                    </v-text-field>
                                                </v-col>
                                                <v-col cols="12">
                                                    <v-btn color="primary" rounded outlined>
                                                        <v-icon left>
                                                            mdi-translate
                                                        </v-icon>
                                                        Traducción
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn  color="primary"
                                                text
                                                @click="close">
                                            Cancelar
                                        </v-btn>
                                        <v-btn  :disabled="!valid"
                                                color="primary"
                                                text
                                                @click="save">
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">Esta seguro que desea eliminar este elemento?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="closeDelete">Cancelar</v-btn>
                                    <v-btn color="blue darken-1" text @click="deleteItemConfirm">Proceder</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.enabled="{ item }">
                    <v-simple-checkbox
                            v-model="item.enabled"
                            disabled
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    class="mr-2"
                                    @click="editItem(item)"
                            >
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    class="mr-2"
                                    @click="deleteItem(item)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">No hay registros en el listado!</span>
                    <br/>
                    <v-btn color="primary" @click="getExtraServices">
                        Recargar
                    </v-btn>
                </template>
            </v-data-table>
            <v-snackbar
                    v-model="snackBar"
                    :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn  v-bind="attrs"
                            icon
                            @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
    import extraservicesService from '@/providers/ExtraServicesService';

    export default {
        name: "ExtraServicesComponent",
        data: () => ({
            snackBar: false,
            snackText: '',
            valid: true,
            selectTranslation: null,
            translations: [],
            searchExtraService: '',
            loadingData: false,
            dialog: false,
            dialogDelete: false,
            extraservices: [],
            headers: [
                {text: 'Id', value: 'id', align: ' d-none'}, // para ocultar la columna Id.
                {
                    text: 'Nombre',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                {text: 'Traducción', value: 'translation_id', align: ' d-none'},
                {text: 'Acciones', value: 'actions', width: 100, sortable: false},
            ],
            editedIndex: -1,
            editedItem: {
                id: 0,
                name: '',
                translation_id: null
            },
            defaultItem: {
                id: 0,
                name: '',
                translation_id: null
            },
            nameRules: [
                v => !!v || 'El Nombre es obligatorio!'
                // v => (v && v.length >= 10) || 'El Nombre debe tener mas de un caracter!',
            ],
        }),
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'Nueva Servicio Extra' : 'Editar Servicio Extra';
            },
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
        },
        methods: {
            getExtraServices() {
                // carga los datos desde el proveedor de datos.
                this.loadingData = true;
                extraservicesService.getAllRecords().then(record => {
                    this.extraservices = record.value;
                    // console.log("Servicios Extras: ", this.extraservices);
                    this.loadingData = false;
                });
            },
            newItem() {
                this.valid = false;
            },
            editItem(item) {
                this.editedIndex = this.extraservices.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.extraservices.indexOf(item);
                this.editedItem = Object.assign({}, item);
                // console.log('Item to delete: %s %s ', this.editedItem.id, this.editedItem);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                this.extraservices.splice(this.editedIndex, 1);
                extraservicesService.deleteRecord(this.editedItem.id);
                // console.log('Item deleted:', this.editedItem);
                this.closeDelete();
                this.snackText = 'Registro eliminado con éxito.';
                this.snackBar = true;
            },
            close() {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                })
            },
            closeDelete() {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                })
            },
            save() {
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    if (this.editedIndex > -1) {
                        Object.assign(this.extraservices[this.editedIndex], this.editedItem);
                        extraservicesService.updateRecord(this.editedItem.id, this.editedItem);
                    } else {
                        this.extraservices.push(this.editedItem);
                        extraservicesService.addRecord(this.editedItem);
                    }
                    this.close();
                    this.snackText = 'Datos del formulario guardado con éxito...';
                    this.snackBar = true;
                    this.getExtraServices();
                }
            },
        },
        mounted() {
            this.extraservices = [];
            this.getExtraServices();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>