<template>
    <extra-services-component/>
</template>

<script>
    import ExtraServicesComponent from "@/components/admin/catalogs/ExtraServicesComponent";
    
    export default {
        name: "ExtraServices",
        title: "Servicios Extras | Turismo BC",
        components: {ExtraServicesComponent},
    }
</script>

<style scoped>

</style>